import product1 from './assets/1.png';
import product2 from './assets/2.png';
import product3 from './assets/3.png';
import product4 from './assets/4.png';
import product5 from './assets/5.png';
import product6 from './assets/6.png';
import product7 from './assets/7.png';
import product8 from './assets/8.png';

export const PRODUCTS = [
    {
        id:1,
        productName: 'PURE 100%',
        price: 90.50,
        productImage: product1,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:2,
        productName: 'COSMAVERA',
        price: 39.65,
        productImage: product2,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:3,
        productName: 'SANTE',
        price: 19.99,
        productImage: product3,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'face',
    },
    {
        id:4,
        productName: 'LEVAR',
        price: 45.00,
        productImage: product4,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:5,
        productName: 'COSMOPOLITAN',
        price: 19.99,
        productImage: product5,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },

    {
        id:6,
        productName: 'FLOWERS',
        price: 68.0,
        productImage: product6,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:7,
        productName: 'STARS ',
        price: 12.00,
        productImage: product7,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
     {
        id:8,
        productName: "PURE",
        price: 40.90,
        productImage: product8,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'face',    
    },

    {
        id:9,
        productName: 'Iphone',
        price: 99.40,
        productImage: product1,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:10,
        productName: 'COSMAVERA',
        price: 25.00,
        productImage: product2,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:11,
        productName: 'SANTE',
        price: 76.00,
        productImage: product3,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'face',
    },
    {
        id:12,
        productName: 'LEVAR',
        price: 23.00,
        productImage: product4,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:13,
        productName: 'COSMOPOLITAN',
        price: 29.99,
        productImage: product5,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },

    {
        id:14,
        productName: 'FLOWERS',
        price: 69.0,
        productImage: product6,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:15,
        productName: 'STARS',
        price: 16.10,
        productImage: product7,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
     {
        id:16,
        productName: "PURE",
        price: 48.0,
        productImage: product8,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'face',    
    },
    {
        id:17,
        productName: 'SANTE',
        price: 19.99,
        productImage: product3,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'face',
    },
    {
        id:18,
        productName: 'LEVAR',
        price: 45.00,
        productImage: product4,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:19,
        productName: 'COSMOPOLITAN',
        price: 19.99,
        productImage: product5,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },

    {
        id:20,
        productName: 'FLOWERS',
        price: 68.0,
        productImage: product6,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:21,
        productName: 'LEVAR',
        price: 23.00,
        productImage: product4,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:22,
        productName: 'COSMOPOLITAN',
        price: 29.99,
        productImage: product5,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },

    {
        id:23,
        productName: 'FLOWERS',
        price: 69.0,
        productImage: product6,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:24,
        productName: 'STARS',
        price: 16.10,
        productImage: product7,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
];

export const PRODUCTS_SLIDE = [
    {
        id:1,
        productName: 'PURE 100%',
        price: 90.50,
        productImage: product1,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:2,
        productName: 'COSMAVERA',
        price: 39.65,
        productImage: product2,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:3,
        productName: 'SANTE',
        price: 19.99,
        productImage: product3,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'face',
    },
    {
        id:4,
        productName: 'LEVAR',
        price: 45.00,
        productImage: product4,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'hair',
    },
    {
        id:5,
        productName: 'COSMOPOLITAN',
        price: 19.99,
        productImage: product5,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },

    {
        id:6,
        productName: 'FLOWERS',
        price: 68.0,
        productImage: product6,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
    {
        id:7,
        productName: 'STARS ',
        price: 12.00,
        productImage: product7,
        desc: 'Lorem Ipsum é simplesmente uma simulação de texto da indústria simulação de texto da indústria ,',
        desc2: 'Lorem Ipsum é simplesmente uma simulação de texto',
        category: 'body',
    },
]